.tabLink {
    border: none;
    background: transparent;
    padding:1%;
    font-size: large;
}

#divTabs {
    background-color: #404040;
    color:grey;
}

#divTabs .tab-link-active{
    color: white;
}

.rbc-time-content, .rbc-time-header-gutter {
    display: none !important;
}

.rbc-time-header-content {
    border-left: none;
}

.rbc-row{
    margin:3px;
}

.rbc-time-view{
    overflow-y: scroll;
}