.dataTables_wrapper > .row div:nth-child(2) {
    width: 100% !important;
    padding-bottom: 1%;
}

.buttonStyle {
    float: right;
    margin-bottom : 2%;
}

.mainDivStyle {
    margin: 1%;
    min-height: 500px;
}

td > div > i:nth-child(2) {
    float: right;
}

#contained-modal-title-vcenter{
    text-align: center;
}

#closeBtn {
    margin-right:14%
}

#deleteBody {
    text-align: center;
    font-size: large;
    font-weight: bold;
}

#activateBtn {
    text-align: center;
}

#modal-form .modal-dialog{
    width:70%;
    margin-left: auto;
    margin-right: auto;
}
#modal-form .container{
    text-align: right;
    width:100%;
    margin-left: auto;
    margin-right: auto;
}

#column-firstName,#column-lastName,#column-email,#column-userStatus,#column-currentDaysLeft,#column-actions,
#column-type,#column-startDate,#column-stopDate,#column-status,#column-reasonMessage,#column-attachments,#column-workingDays,
#column-name,#column-endDate,#column-workedDays{
    font-weight: 800;
}

.dropdown-menu{
    min-width: 0;
}
