.warningMessage {
    color: red;
    height: 0;
}

#addPeriodForm {
    padding: 7%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 46px;
    box-shadow: 5px 5px 15px #cbced1;
    background-color: #d3eef9;
}

#rowStyleForm {
    margin-top: 7%;
}
