.cardStyle {
    margin:2%;
    border: 1px solid #ebf5fc;
    /* box-shadow: 5px 5px 15px #cbced1; */
}

.contentStyle {
    padding: 15%;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.imageStyle {
    width: 100px;
    border-radius: 45px;
    overflow: hidden;
} 

.infoCol {
    padding: 3%;
}

.nameStyle {
    text-transform: uppercase;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 15px;
}

.emailStyle {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}